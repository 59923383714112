/* Ensure the full height of the screen is used */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the main content vertically */
  align-items: center;
  background-color: #162b3b;
  padding: 40px;
}

/* Styles for a larger box with double border */
.center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content and footer are spaced */
  align-items: center;
  padding: 40px;
  background-color: white;
  border: 7px solid #162b3b; /* Inner border color (first layer) */
  box-shadow: 0 0 0 7px white; /* Outer border color (second layer) */
  border-radius: 15px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  height: 600px;
  margin: 0 auto;
  flex-grow: 1; /* Allows the box to grow and push the footer down */
}

/* Styles for the circular image with border and shadow */
.center-box img {
  width: 300px; /* Keep the image size as needed */
  height: 250px; /* Set height to match the width for a perfect circle */
  border-radius: 50%; /* This creates the circular shape */
  object-fit: cover; /* Ensures the image fits within the circle without stretching */
}

.description {
  font-weight: bold;
  color: #162b3b;
  font-size: 25px;
}

/* Footer styling inside the center box */
.px-4 {
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px;
  margin-top: auto; /* Push the footer to the bottom of the box */
  background-color: #162b3b; /* You can adjust the background color */
  border-radius: 0 0 15px 15px; /* Match the border-radius of the box */
}

/* Link styling */
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
}
